.sort {
  margin-top: 7px;
  padding: 0;

  select {
    padding: 3px;
    border-radius: 2px;
    display: none;
    margin-bottom: 5px;
    @media (max-width: 768px) {
      margin-right: 10px;
      font-size: 0.8rem;
    }
  }

  @media (max-width: 768px) {
    select {
      display: block;
    }
    span,
    .icon {
      display: none;
    }
  }
}

.icon {
  margin-left: 5px;
  cursor: pointer;
  &:hover {
    fill: tomato;
  }
}

.iconActive {
  fill: tomato;
}

.spiner {
  position: absolute;
  left: 50%;
  top: 45%;
}
