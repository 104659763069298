@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600&display=swap");
@import "~bootstrap/scss/bootstrap";

body {
  font-family: "Montserrat", sans-serif;
  background-color: rgb(246, 250, 250);
}

img {
  width: 100%;
}
